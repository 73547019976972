import styled from 'styled-components'

interface MissionItemProps {
  isActive: boolean
}

export const Container = styled.div`
  display: flex;
  overflow: hidden;
  transition: opacity 0.3s ease-in-out;
  flex-direction: column;
  align-items: center;
  max-width: calc(100vw - 50px);

  position: relative;

  box-shadow: 0px 12px 16px rgba(101, 101, 101, 0.09);
  border: 1px solid #eaeaea;

  border-radius: 10px;
  margin-bottom: 24px;
  padding: 18px 14px;

  figure {
    margin: 20px 24px;
    img {
      border-radius: 10px;
      height: 300px;
      width: 300px;
      object-fit: cover;
    }
  }
  p {
    text-align: center;
    strong {
      font-size: 20px;
      margin-bottom: 12px;
    }
  }
  strong {
    font-size: 16px;
    margin-bottom: 15px;
    text-align: center;
  }

  @media screen and (min-width: 768px) {
    figure {
      img {
        height: 375px;
        width: 375px;
      }
    }
  }

  @media screen and (min-width: 990px) {
    figure {
      img {
        height: 450px;
        width: 450px;
      }
    }
  }
`

export const CounterIdx = styled.div`
  position: absolute;
  top: 9px;
  left: 7px;
  border: 2px solid ${props => props.theme.colors.backgroundTertiary};
  padding: 6px 12px;
  border-radius: 10px;
  color: ${props => props.theme.colors.backgroundSecondary};
  font-size: 21px;
  font-weight: bold;
  z-index: 2;
`
