import styled from 'styled-components'

export const Container = styled.div`
  flex: 1;
  height: 100%;
  h1 {
    text-align: center;
    margin-bottom: 20px;
  }
  h2 {
    margin-bottom: 15px;
    margin-top: 20px;
  }
  p {
    margin-bottom: 8px;
    max-width: 900px;
  }
`
