/* eslint-disable prettier/prettier */
const questionTypes =
  process.env.NODE_ENV === 'development'
    ? {
      truthOrMythId: 'fa2252fe-d0fe-4fd1-8de0-213354bf41c5',
      positiveNegativeId: '20221e52-3a7f-4dc7-972a-4d469f981f8f',
      quizId: '5a5bd475-f75d-4bea-9bd2-5367efbae4f7',
    }
    : {
      truthOrMythId: '265a9372-1273-489c-8b4b-c64e270fbd4a',
      positiveNegativeId: '97fa9793-4316-4031-bea0-c4b48e69b1a6',
      quizId: 'd8bbf782-6635-4c73-8349-04d2e81ce4ca',
    }

export default questionTypes
