import React, { createContext, useCallback, useState, useContext, useEffect } from 'react'

import { decode } from 'jsonwebtoken'

import { useUserStats } from './userStats'

import api from '../services/api'

interface UserDetails {
  cpf: string
  birthdate: string
  phone: string
  firstAccess: boolean
  lastLogin: Date
}
interface AuthState {
  token: string
  user: {
    id: string
    name: string
    email: string
    avatar: string
    userDetails: UserDetails
  }
}

interface SignInCredentials {
  email: string
  password: string
}

interface AuthContextData {
  user: {
    id: string
    name: string
    email: string
    avatar: string
    userDetails: UserDetails
  }

  signIn(credentials: SignInCredentials): Promise<void>
  signOut(): void
  updateProfile(user: AuthContextData['user']): void
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData)

const AuthProvider: React.FC = ({ children }) => {
  const [expired, setExpired] = useState(false)
  const [data, setData] = useState<AuthState>(() => {
    const token = localStorage.getItem(
      `@${process.env.REACT_APP_PERSISTED_STATE_KEY}:token`,
    )
    const user = localStorage.getItem(
      `@${process.env.REACT_APP_PERSISTED_STATE_KEY}:user`,
    )

    if (token && user) {
      const { exp } = decode(token) as { exp: number | 0; iat: number | 0 }

      // o token está expirado.
      if (Date.now() > exp * 1000) {
        setExpired(true)
        return {} as AuthState
      }

      api.defaults.headers.Authorization = `Bearer ${token}`
      return { token, user: JSON.parse(user) }
    }
    return {} as AuthState
  })

  const { setUserStats, clearUserStats } = useUserStats()

  const signIn = useCallback(
    async ({ email, password }) => {
      const response = await api.post('/sessions', {
        email,
        password,
      })

      const { token, user, userDetails, userStats } = response.data

      localStorage.setItem(`@${process.env.REACT_APP_PERSISTED_STATE_KEY}:token`, token)
      localStorage.setItem(
        `@${process.env.REACT_APP_PERSISTED_STATE_KEY}:user`,
        JSON.stringify({ ...user, userDetails }),
      )

      setUserStats(userStats)
      setData({ token, user: { ...user, userDetails } })

      api.defaults.headers.Authorization = `Bearer ${token}`
    },
    [setUserStats],
  )

  const updateProfile = useCallback(
    (newUser: AuthContextData['user']) => {
      if (newUser.id === data?.user?.id) {
        localStorage.setItem(
          `@${process.env.REACT_APP_PERSISTED_STATE_KEY}:user`,
          JSON.stringify(newUser),
        )

        setData({ token: data.token, user: newUser })
      }
    },
    [data],
  )

  const signOut = useCallback(() => {
    localStorage.removeItem(`@${process.env.REACT_APP_PERSISTED_STATE_KEY}:token`)
    localStorage.removeItem(`@${process.env.REACT_APP_PERSISTED_STATE_KEY}:user`)
    setData({} as AuthState)
    clearUserStats()
    setExpired(false)
  }, [clearUserStats])

  useEffect(() => {
    if (expired) {
      signOut()
    }
  }, [expired, signOut])

  return (
    <AuthContext.Provider value={{ user: data.user, signIn, signOut, updateProfile }}>
      {children}
    </AuthContext.Provider>
  )
}

function useAuth(): AuthContextData {
  const context = useContext(AuthContext)
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider')
  }
  return context
}

export { AuthProvider, useAuth }
