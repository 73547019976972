import React, { useState } from 'react'

import { Link } from 'react-router-dom'

import { FaChevronDown, FaChevronUp } from 'react-icons/fa'

import { useAuth } from '../../../../hooks/auth'
import { useUserStats } from '../../../../hooks/userStats'

import { Avatar } from '../../../../components'

import coinsImage from '../../../../assets/coins.svg'

import { Container, DivAll, Content, Header } from './styles'
import formatValue from '../../../../utils/formatValue'

const Sidebar: React.FC = () => {
  const { user } = useAuth()
  const { userStats } = useUserStats()

  const [displayable, setDisplayable] = useState(false)

  return (
    <Container>
      <DivAll>
        <div>
          <button type="button" onClick={() => setDisplayable(!displayable)}>
            Pontos, dicas e mais...
            {displayable ? <FaChevronUp size={24} /> : <FaChevronDown size={24} />}
          </button>
        </div>
        <Content show={displayable}>
          <div>
            <Header>
              <Link to="/profile">
                <Avatar size={120} avatar={user?.avatar} name={user?.name} />
                <h3>{user?.name}</h3>
              </Link>
              <article>
                <img src={coinsImage} alt="Coins" />
                <div>
                  <span>{formatValue(userStats?.currentBudget)}</span>
                  <p>Custo Anual</p>
                </div>
              </article>

              {/* <XpGameStats>
                <img src={xpImage} alt="XP" />
                {userStats?.experience}
              </XpGameStats> */}
            </Header>
          </div>

          {/* <div>
            <Dicas>
              <h2>Dicas</h2>
              <p>
                Compartilhe seu código de indicação <strong>{user?.name}</strong> para
                ganhar moedas quando seus amigos se cadastram no game!{' '}
              </p>
            </Dicas>
          </div> */}
        </Content>
      </DivAll>
    </Container>
  )
}

export default Sidebar
