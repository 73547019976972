import React, { useContext, useEffect, useState } from 'react'
import { ThemeContext } from 'styled-components'
import Button from '../../../../components/Button'
import api from '../../../../services/api'

import { Container, CounterIdx } from './styles'

interface Props {
  id: string
  name: string
  description: string
  picture: string
  handleStart(id: string): void
  idx: number
}

interface Stats {
  budget: number
  currentQuestionId: string
  missionId: string
  userId: string
  id: string
  finalizedAt?: Date
}

const MissionItem: React.FC<Props> = ({
  id,
  idx,
  name,
  description,
  picture,
  handleStart,
}) => {
  const { colors } = useContext(ThemeContext)

  const [stats, setStats] = useState<Stats>({} as Stats)

  useEffect(() => {
    async function loadStats(): Promise<void> {
      try {
        const response = await api.get(`/missions/${id}/stats`)
        const { missionStats } = response.data
        setStats(missionStats)
      } catch (err) {
        console.error(`Error to loading stats of mission ${id}`)
      }
    }

    loadStats()
  }, [id])

  return (
    <Container>
      <figure>
        <img src={`${process.env.REACT_APP_API_URL}/files/${picture}`} alt={name} />
      </figure>
      <p>
        <strong>{name}</strong>
      </p>
      {/* <p>{description}</p> */}

      <Button
        onClick={() => handleStart(id)}
        backgroundColor={colors.backgroundSecondary}
      >
        {!!stats && stats.finalizedAt ? 'Missão Finalizada' : 'Começar'}
      </Button>
      <CounterIdx>{idx + 1}</CounterIdx>
    </Container>
  )
}

export default MissionItem
