import React, {
  InputHTMLAttributes,
  useEffect,
  useRef,
  useCallback,
  useState,
} from 'react'

import { IconBaseProps } from 'react-icons'
import { FiAlertCircle, FiCalendar } from 'react-icons/fi'

import { useField } from '@unform/core'

import { Container, Error } from './styles'

import masks from '../../../../utils/masks'

interface Props extends InputHTMLAttributes<Omit<HTMLInputElement, 'type'>> {
  name: string
  placeholder: string
  icon?: boolean
  sizeBox?: 'small' | 'large'
  prefix?: string
  sufix?: string
}

const Date: React.FC<Props> = ({
  prefix,
  sufix,
  placeholder,
  name,
  icon,
  sizeBox = 'small',
  ...rest
}) => {
  const [isFocused, setIsFocused] = useState(false)
  const [isFilled, setIsFilled] = useState(false)

  const inputRef = useRef<HTMLInputElement>(null)
  const { fieldName, defaultValue, error, clearError, registerField } = useField(name)

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    })
  }, [fieldName, registerField])

  const handleInputFocus = useCallback(() => {
    setIsFocused(true)
  }, [])

  const handleInputBlur = useCallback(() => {
    setIsFocused(false)
    setIsFilled(!!inputRef.current?.value)

    if (inputRef.current?.value) clearError()
  }, [clearError])

  useEffect(() => {
    setIsFilled(!!inputRef.current?.value)
  }, [])

  return (
    <Container
      isErrored={!!error}
      isFilled={isFilled}
      isFocused={isFocused}
      sizeBox={sizeBox}
      hasSufix={!!sufix}
    >
      {(isFilled || isFocused) && icon && <FiCalendar size={20} />}
      {(isFilled || isFocused) && prefix && <span>{prefix}</span>}

      <input
        type="date"
        onFocus={handleInputFocus}
        onBlur={handleInputBlur}
        defaultValue={defaultValue}
        ref={inputRef}
        {...rest}
      />

      {(isFilled || isFocused) && sufix && <span>{sufix}</span>}
      {error && (
        <Error title={error}>
          <FiAlertCircle color="#c53030" size="20" />
        </Error>
      )}
      <label htmlFor={fieldName}>{placeholder}</label>
    </Container>
  )
}

export default Date
