import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'

// import { BsChevronDoubleLeft, BsChevronDoubleRight } from 'react-icons/bs'
import { useHistory } from 'react-router-dom'
import { ThemeContext } from 'styled-components'
import Button from '../../components/Button'
import { useAuth } from '../../hooks/auth'
import { useLayoutInfos } from '../../hooks/layoutInfos'
import { useToast } from '../../hooks/toast'
import { useUserStats } from '../../hooks/userStats'
import api from '../../services/api'
import MissionItem from './components/MissionItem'
// import Dots from './components/Dots'

import { Container, DivContent, DivContentTrilha, Footer } from './styles'

interface MissionData {
  id: string
  name: string
  description: string
  picture: string
}

const Dashboard: React.FC = () => {
  const { user } = useAuth()
  const { userStats } = useUserStats()
  const history = useHistory()
  const { setLayoutInfos } = useLayoutInfos()
  const { colors, title } = useContext(ThemeContext)

  const { addToast } = useToast()

  const [missions, setMissions] = useState<MissionData[]>([])
  const [missionActive, setMissionActive] = useState(0)
  const isFirst = useMemo(() => missionActive === 0, [missionActive])
  const isLast = useMemo(() => missionActive >= missions.length - 1, [
    missionActive,
    missions.length,
  ])

  useEffect(() => {
    async function loadMissions(): Promise<void> {
      addToast({ type: 'info', title: 'Carregando Missões' })
      try {
        const response = await api.get('/missions')

        const { missions: allMissions } = response.data

        setMissions(allMissions)

        if (!allMissions.length) {
          throw new Error('Não existe missões.')
        }

        setMissionActive(0)
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Falha ao carregar missões',
          description: err.message,
        })
      }
    }

    loadMissions()
  }, [addToast])

  useEffect(() => {
    setLayoutInfos({
      title: 'Bem vindo!',
      description: `${user?.name.split(' ')[0]}
      , chegou a hora de aprender e testar seus conhecimentos!`,
    })
  }, [setLayoutInfos, user?.name])

  useEffect(() => {
    if (!user.userDetails?.firstAccess) {
      history.push('/infos')
    }
  }, [history, user.userDetails?.firstAccess])

  useEffect(() => {
    if (userStats && userStats.gameFinished) {
      history.push(`/game-finished`)
    }
  }, [history, userStats])

  const handleSetNext = useCallback(() => {
    if (missionActive + 1 <= missions.length - 1) {
      setMissionActive(missionActive + 1)
    } else {
      setMissionActive(0)
    }
  }, [missionActive, missions.length])

  const handleSetPrev = useCallback(() => {
    if (missionActive - 1 >= 0) {
      setMissionActive(missionActive - 1)
    } else {
      setMissionActive(missions.length - 1)
    }
  }, [missionActive, missions.length])

  // const handleStart = useCallback(() => {
  //   history.push(`/mission/${missions[missionActive].id}`, {
  //     mission: missions[missionActive],
  //   })
  // }, [history, missionActive, missions])

  const handleStart = useCallback(
    id => {
      history.push(`/mission/${id}`, {
        mission: missions.filter(mission => mission.id === id)[0],
      })
    },
    [history, missions],
  )

  return (
    <>
      {userStats && !userStats.gameFinished && (
        <Container>
          <DivContentTrilha>
            {!!missions.length &&
              missions.map((mission, idx) => (
                <MissionItem
                  key={mission.id}
                  description={mission.description}
                  name={mission.name}
                  handleStart={handleStart}
                  picture={mission.picture}
                  id={mission.id}
                  idx={idx}
                />
              ))}
          </DivContentTrilha>

          {/* <Footer>
            <Button
              type="button"
              onClick={handleSetPrev}
              backgroundColor="transparent"
              textColor={colors.backgroundSecondary}
              disabled={isFirst}
            >
              <BsChevronDoubleLeft size={35} />
            </Button>

            <Dots quantity={missions.length} active={missionActive} />

            <Button
              type="button"
              backgroundColor="transparent"
              textColor={colors.backgroundSecondary}
              onClick={handleSetNext}
              disabled={isLast}
            >
              <BsChevronDoubleRight size={35} />
            </Button>
          </Footer> */}
        </Container>
      )}
    </>
  )
}

export default Dashboard
