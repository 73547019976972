import React, { useEffect, useMemo, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useMissionStats } from '../../../hooks/missionStats'
import { useToast } from '../../../hooks/toast'
import { useUserStats } from '../../../hooks/userStats'

import questionTypes from '../../../config/questionTypes'

import { Container } from './styles'
import TruthOrMyth from './TruthOrMyth'

import PositiveNegative from './PositiveNegative'

interface Question {
  id: string
  missionId: string
  questionTypeId: string
  budgetValue: number
}

interface TruthOrMythQuestion {
  id: string
  questionId: string
  questionContent: string
  description: string
  isTruth: boolean
}

interface PositiveNegativeQuestion {
  id: string
  questionId: string
  questionContent: string
  answerIfPositive: string
  answerIfNegative: string
}

interface QuizQuestionOption {
  id: string
  option: string
  isAnswer: boolean
  quizQuestionId: string
}

interface QuizQuestion {
  questionId: string
  questionContent: string
  quizQuestionOptions: QuizQuestionOption[]
}

export interface QuestionWithContent extends Question {
  questionData: TruthOrMythQuestion | PositiveNegativeQuestion | QuizQuestion
}

interface Props {
  questions: QuestionWithContent[]
}

const Playing: React.FC<Props> = ({ questions }) => {
  const { addToast } = useToast()
  const history = useHistory()
  const { missionId } = useParams<{ missionId: string }>()
  const { setMissionStats, missionStats } = useMissionStats()
  const { updateUserStats } = useUserStats()

  // const [currentQuestionIndex, setCurrentQuestionIndex] = useState(() => {
  //   return questions.findIndex(question => question.id === missionStats.currentQuestionId)
  // })

  const currentQuestionIndex = useMemo(
    () => questions.findIndex(question => question.id === missionStats.currentQuestionId),
    [missionStats.currentQuestionId, questions],
  )

  const currentQuestion = useMemo(() => questions[currentQuestionIndex], [
    currentQuestionIndex,
    questions,
  ])

  return (
    <Container>
      {!!currentQuestion &&
        (() => {
          switch (currentQuestion.questionTypeId) {
            case questionTypes.truthOrMythId: {
              return (
                <TruthOrMyth
                  question={{
                    ...currentQuestion,
                    questionData: currentQuestion.questionData as TruthOrMythQuestion,
                  }}
                />
              )
              break
            }
            case questionTypes.positiveNegativeId: {
              return (
                <PositiveNegative
                  question={{
                    ...currentQuestion,
                    questionData: currentQuestion.questionData as PositiveNegativeQuestion,
                  }}
                />
              )
              break
            }
            default: {
              return <h1>Erro ao carregar questão.</h1>
            }
          }
        })()}

      <small>
        {currentQuestionIndex + 1} de {questions.length} questões
      </small>
    </Container>
  )
}

export default Playing
