import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const DivContent = styled.main`
  display: flex;
  flex-direction: row;
  width: calc(100vw - 40px);
  /* border: 2px solid green; */
  height: 500px;
  @media screen and (min-width: 700px) {
    width: 600px;
  }
  @media screen and (min-width: 768px) {
    height: 550px;
  }

  @media screen and (min-width: 990px) {
    width: 800px;
    height: 600px;
  }
`

export const DivContentTrilha = styled.main`
  display: flex;
  flex-direction: column;
  width: calc(100vw - 40px);
  padding: 10px 15px;
  /* border: 2px solid green; */
  @media screen and (min-width: 700px) {
    width: 600px;
  }

  @media screen and (min-width: 990px) {
    width: 800px;
    padding: 18px 10px;
  }
`

export const Footer = styled.footer`
  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: center;
  height: 80px;
  > button {
    margin-top: 0;
  }
`
