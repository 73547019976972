import React, { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import Button from '../../components/Button'
import { useAuth } from '../../hooks/auth'
import { useToast } from '../../hooks/toast'
import api from '../../services/api'

import { Container } from './styles'

const Infos: React.FC = () => {
  const { user, updateProfile } = useAuth()
  const history = useHistory()
  const { addToast } = useToast()

  const handleStartGame = useCallback(async () => {
    if (!user.userDetails?.firstAccess) {
      api.patch('/users/firstAccess').then(response => {
        const { user: updatedUser, userDetails: updatedUserDetails } = response.data

        updateProfile({ ...updatedUser, userDetails: updatedUserDetails })

        addToast({
          type: 'info',
          title: 'Começando o jogo',
          description: '',
        })

        history.push('/dashboard')
      })
    } else {
      history.push('/dashboard')
    }
  }, [addToast, history, updateProfile, user.userDetails?.firstAccess])

  return (
    <Container>
      <h1>Informações</h1>
      <p>
        Esse jogo foi feito para refletir sobre seus hábitos em relação a sua saúde e como
        isso pode impactar na sua vida financeira. Portanto, tente fazer o jogo de uma
        maneira mais sincera possível, para que o jogo faça sentido para você. Para nosso
        exemplo ficar mais didático, inventamos uma moeda chamada GRANDEZA (G$).{' '}
      </p>
      <p>
        Vamos supor que você possui um plano de saúde e o custo dele é de{' '}
        <strong>7 mil grandezas anualmente</strong>. O objetivo desse jogo é mostrar como
        seu comportamento pode alterar o custo do plano para você!
      </p>
      <p>
        Lembramos que esse jogo é um exercício hipotético e é apenas para refletir seus
        hábitos em relação a sua saúde. O valor do seu plano não vai mudar por causa do
        jogo.
        <br />
        Certo? Vamos lá!
      </p>
      <h2>Regras do Game</h2>
      <p>
        Ao longo desse jogo, você responderá diversas questões onde cada uma delas será
        responsável por aumentar o diminuir seu custo anual do plano.
      </p>
      <p>
        Em questões do tipo <strong>Verdade ou Mito</strong>, cada questão que você
        acertar fará seu plano ficar mais barato. Da mesma forma, caso erre alguma
        resposta, seu plano ficará mais caro.
      </p>
      <p>
        Em questões do tipo <strong>Check</strong>, suas respostas Positivas farão seu
        plano ficar mais barato e suas respostas negativas farão o plano ficar mais caro.
      </p>
      <h2>Resultado</h2>
      <p>
        Ao final do jogo, você terá seu resultado do plano, que mostrará se você conseguiu
        economizar ao logo do processo ou se acabou gastando além do seu orçamento
        inicial.
      </p>
      <p>
        Lembre-se: esse jogo é apenas uma reflexão à respeito dos seus hábitos. Tente ser
        o mais sincero possível em suas respostas.
      </p>
      {!user.userDetails?.firstAccess && (
        <Button type="button" onClick={handleStartGame}>
          Começar!
        </Button>
      )}
      {user.userDetails?.firstAccess && (
        <Button type="button" onClick={() => history.push('/dashboard')}>
          Voltar
        </Button>
      )}
    </Container>
  )
}

export default Infos
