import React, { useCallback, useEffect, useState } from 'react'
import ColoredBox from '../../../components/ColoredBox'
import LineGraph from '../../../components/LineGraph'
import { useAuth } from '../../../hooks/auth'
import { useUserStats } from '../../../hooks/userStats'
import formatValue from '../../../utils/formatValue'

import { Container, Content, GraphWrapper } from './styles'

interface Values {
  year: number
  invested: number
  finalBalance: number
  rentability: number
}

const Simulator: React.FC = () => {
  const { userStats } = useUserStats()
  const { user } = useAuth()

  const [economizedValue, setEconomizedValue] = useState(0)
  const [values, setValues] = useState<Values[]>([])
  const [totalInvested, setTotalInvested] = useState(0)
  const [totalBalance, setTotalBalance] = useState(0)
  const [totalRentability, setTotalRentability] = useState(0)
  const [launched, setLaunched] = useState(false)

  useEffect(() => {
    if (userStats) {
      setEconomizedValue(userStats.initialBudget - userStats.currentBudget)
    }
  }, [userStats, userStats.currentBudget, userStats.initialBudget])

  useEffect(() => setTotalRentability((totalBalance * 100) / totalInvested), [
    totalInvested,
    totalBalance,
    setTotalRentability,
  ])

  const createValues = useCallback(() => {
    console.log(`econ`, economizedValue)
    if (economizedValue > 0) {
      const contribution = economizedValue
      const years = 15

      const actualYear = new Date().getFullYear()
      const lastYear = actualYear + parseInt(years.toString(), 10)

      const valuesCalculated: Values[] = []

      const investedByYear = contribution // 12 * contribution

      let totalInvestedTemp = totalInvested

      let temporaryFinalBalance = 0

      for (let i = actualYear; i <= lastYear; i += 1) {
        const yearInvested = investedByYear /*+
        (contribution * (-80 + 100 * Math.random() + 100)) / 100 */
        const rentability = 4.23 // -8 + 28 * Math.random()

        totalInvestedTemp += yearInvested

        const finalBalance =
          ((temporaryFinalBalance + yearInvested) * (rentability + 100)) / 100

        temporaryFinalBalance = finalBalance

        const val: Values = {
          year: i,
          invested: totalInvestedTemp,
          rentability,
          finalBalance,
        }

        valuesCalculated.push(val)
      }

      setTotalInvested(totalInvestedTemp)
      setTotalBalance(temporaryFinalBalance)

      setValues(valuesCalculated)
      setLaunched(true)
    }
  }, [economizedValue, totalInvested])

  useEffect(() => {
    if (!launched) createValues()
  }, [createValues, launched])

  return (
    <Container>
      <Content>
        <header>
          <ColoredBox color="pink" gradientDirection="left">
            <span>Valor Investido</span>
            <h3>{formatValue(totalInvested)}</h3>
            <small>Seu investimento no período</small>
          </ColoredBox>

          <ColoredBox color="green" gradientDirection="left">
            <span>Seu saldo projetado</span>
            <h3>{formatValue(totalBalance)}</h3>
            <small>Seu investimento + rentabilidade</small>
          </ColoredBox>
        </header>
        <article>
          <p>Os dados não mentem.</p>
          <p>
            Quer ver? Arraste seu dedo no gráfico e descubra a evolução do seu patrimônio
            no decorrer dos próximos anos.
          </p>
        </article>
        <GraphWrapper>
          <LineGraph
            data={[
              {
                id: 'Saldo',
                data: values.map(value => ({
                  x: new Date(`12-31-${value.year}`).toISOString(),
                  y: value.finalBalance,
                })),
              },
              {
                id: 'Valor Investido',
                data: values.map(value => ({
                  x: new Date(`12-31-${value.year}`).toISOString(),
                  y: value.invested,
                })),
              },
            ]}
          />
        </GraphWrapper>
      </Content>
    </Container>
  )
}

export default Simulator
