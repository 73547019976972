import React from 'react'
import { Switch } from 'react-router-dom'

import Route from './Route'

// import SignIn from '../pages/SignIn'
// import SignUp from '../pages/SignUp'

// import Dashboard from '../pages/Dashboard'
// import Game from '../pages/Game'
// import Profile from '../pages/Profile'
// import Ranking from '../pages/Ranking'
// import World from '../pages/World'

import Home from '../pages/Home'
import Recovery from '../pages/Recovery'
import Dashboard from '../pages/Dashboard'
import Profile from '../pages/Profile'
import Ranking from '../pages/Ranking'
import Infos from '../pages/Infos'
import Game from '../pages/Game'
import GameFinished from '../pages/GameFinished'
import Reset from '../pages/Reset'

const Routes: React.FC = () => {
  return (
    <Switch>
      <Route path="/" exact component={Home} />
      <Route path="/recovery" component={Recovery} />
      <Route path="/reset" component={Reset} />
      <Route path="/dashboard" component={Dashboard} isPrivate />
      <Route path="/game-finished" component={GameFinished} isPrivate />
      <Route path="/profile" component={Profile} isPrivate />
      <Route path="/infos" component={Infos} isPrivate />
      <Route path="/ranking" component={Ranking} isPrivate />
      <Route path="/mission/:missionId" exact component={Game} isPrivate />

      {/* <Route path="/" exact component={SignIn} />
      <Route path="/signup" component={SignUp} />

      <Route path="/dashboard" component={Dashboard} isPrivate />
      <Route
        path="/world/:worldId/mission/:missionId"
        exact
        component={Game}
        isPrivate
      />
      <Route path="/world/:worldId" exact component={World} isPrivate /> */}
    </Switch>
  )
}

export default Routes
