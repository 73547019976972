import React from 'react'
import {
  RouteProps as ReactDOMRouterProps,
  Route as ReactDOMRoute,
  Redirect,
} from 'react-router-dom'

import { useAuth } from '../hooks/auth'
import Infos from '../pages/Infos'

import Default from '../pages/_layouts/Default'
import Logged from '../pages/_layouts/Logged'

interface RouteProps extends ReactDOMRouterProps {
  isPrivate?: boolean
  component: React.ComponentType
}

const Route: React.FC<RouteProps> = ({
  isPrivate = false,
  component: Component,
  ...rest
}) => {
  const { user } = useAuth()

  let PageRendered: JSX.Element

  if (isPrivate) {
    PageRendered = (
      <Logged>
        <Component />
      </Logged>
    )
  } else {
    PageRendered = (
      <Default>
        <Component />
      </Default>
    )
  }

  if (isPrivate === !!user) {
    return <ReactDOMRoute {...rest} render={() => PageRendered} />
  }

  return (
    <ReactDOMRoute
      {...rest}
      render={({ location }) => {
        return (
          <Redirect
            to={{
              state: { from: location },
              pathname: isPrivate ? '/' : '/dashboard',
            }}
          />
        )
      }}
    />
  )
}

export default Route
