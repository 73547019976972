import styled, { css } from 'styled-components'

import { tint, shade } from 'polished'

export const Container = styled.div`
  max-width: 900px;
  margin: 0 auto;
  width: 100%;
  flex: 1;

  display: flex;

  justify-content: center;
`

export const Content = styled.div`
  max-width: 600px;

  width: 100%;
`

export const TabLinks = styled.div`
  display: flex;
`

interface ButtonTabProps {
  active: boolean
  position: 'left' | 'right'
}

export const ButtonTab = styled.button<ButtonTabProps>`
  background: none;

  border: 0;

  text-decoration: none;

  display: block;

  flex: 1;

  border: 1px solid ${({ theme }) => theme.colors.backgroundSecondary};
  border-bottom: none;

  height: 60px;

  font-size: 24px;

  font-weight: 500;

  transition: 0.2s ease-in-out;

  color: ${props => props.theme.colors.textSecondary};

  background-color: ${props => props.theme.colors.backgroundSecondary};
  &:hover {
    background-color: ${({ theme }) => shade(0.1, theme.colors.backgroundSecondary)};
  }

  ${props =>
    props.active &&
    css`
      background-color: ${props.theme.colors.backgroundTertiary};

      color: ${props.theme.colors.text};
      &:hover {
        background-color: ${props.theme.colors.backgroundTertiary};
      }
    `}

  ${props =>
    props.position === 'left' &&
    css`
      border-top-left-radius: 5px;
    `}



  ${props =>
    props.position === 'right' &&
    css`
      border-top-right-radius: 5px;
    `}
`
export const TabContent = styled.div`
  padding: 15px 20px;

  border: 1px solid ${({ theme }) => theme.colors.backgroundSecondary};

  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top: none;

  background-color: ${({ theme }) => theme.colors.backgroundTertiary};
`

interface TabItemProps {
  active: boolean
}

export const TabItem = styled.section<TabItemProps>`
  display: ${props => (props.active ? 'block' : 'none')};

  /* border: 1px solid black; */

  > form h1 {
    text-align: center;
    margin-bottom: 12px;
  }

  footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    > button {
      margin-top: 0;
    }
    > a {
      text-decoration: none;
      color: ${({ theme }) => theme.colors.backgroundSecondary};

      transition: all 0.2s ease-in-out;
      &:hover {
        color: ${({ theme }) => shade(0.1, theme.colors.backgroundSecondary)};
      }
    }
  }
`
